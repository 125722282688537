import React, { useState } from "react";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoIosWarning } from "@react-icons/all-files/io/IoIosWarning";
import { Link as RLink } from "react-router-dom";

import { Modal, ModalHeader, ModalBody, ModalCloseBtn, ModalActions } from "Molecules";
import { Terminal } from "Types";
import { heartBeatMessage } from "TempUtils";
import { IModalContext, useLanguage } from "Providers";
import {
    Button,
    RHCheckBoxInput,
    RHFormInput,
    RHSelectInput,
    NewGrid as Grid,
    Indicator,
    Flex,
    Box,
    Text
} from "Atoms";
import { modalNames } from "Constants";
import { removeNullsInObject } from "Utils";
import { POS, MenusForPOS } from "../utils/posTypes";
import { getPosInitialValues } from "../utils/posInitialValues";
import { useQopla } from "Providers";

type Props = {
    shopId: string;
    menus: MenusForPOS[];
    terminals: Terminal[];
    updatePos: (pos: POS) => Promise<void>;
    posToEdit?: POS;
};

export const PosForm: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { shopId, posToEdit, menus, terminals, updatePos } = modalContent;
    const { selectedShop, selectedCompany } = useQopla();
    const [printerIsConnected, setPrinterIsConnected] = useState(false);
    const { translate } = useLanguage();

    const checkPrinterIp = async (ip: string, deviceName: string) => {
        const heartBeatResult = await heartBeatMessage(ip, deviceName);
        setPrinterIsConnected(heartBeatResult);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2, translate("forCard")).required(translate("formErrorMissing")),
        receiptPrinter: Yup.object().shape({
            ip: Yup.string()
                .required(translate("formErrorMissing"))
                .matches(
                    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    translate("ipWrongFormat")
                )
        })
    });

    const modalHeader = posToEdit?.name ? `${translate("modify")} ${posToEdit.name}` : translate("addNewCashRegister");

    const isNewPos = !posToEdit?.name;
    const hasNoEmailToUse = !selectedShop?.contactPerson?.email && !selectedCompany?.email;
    const canNotSubmitNewPos = isNewPos && hasNoEmailToUse;

    const terminalOptions = terminals
        ? terminals.map(terminal => ({
              label: terminal.name,
              value: terminal.id
          }))
        : [];

    const posToEditFilteredMenus = {
        shopId: shopId,
        ...posToEdit,
        menuIds: posToEdit?.menuIds.filter(menuId => menus.some(availableMenu => availableMenu.id === menuId)),
        connectedTerminalIds: terminalOptions
            .filter(terminalOption => posToEdit?.connectedTerminalIds.includes(terminalOption.value || ""))
            .map(terminal => terminal.value)
    };

    const { initialValues } = getPosInitialValues(false, posToEditFilteredMenus as POS);
    const { deviceName } = initialValues.receiptPrinter;

    const menuOptions = menus?.map(menu => ({ label: menu.name, value: menu.id, isDisabled: menu.disabled })) ?? [];

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, isValid, isDirty }
    } = useForm<POS>({
        defaultValues: initialValues,
        mode: "onBlur",
        resolver: yupResolver(validationSchema)
    });

    const allowPhonePostponeOrders = useWatch({ control, name: "allowPhonePostponeOrders" });
    const postponePaymentEnabled = useWatch({ control, name: "postponePaymentEnabled" });
    const receiptIpAddress = useWatch({ control, name: "receiptPrinter.ip" });

    const onSubmit = async (formValues: POS) => {
        const removeNulls = removeNullsInObject<POS>(formValues) as POS;
        await updatePos(removeNulls);
        closeModal(modalNames.POS_ADMIN_MODAL);
    };

    return (
        <Modal open={true} onClose={() => closeModal(modalNames.POS_ADMIN_MODAL)} isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.POS_ADMIN_MODAL)} />
            <ModalHeader>{modalHeader}</ModalHeader>
            {canNotSubmitNewPos && (
                <Flex alignItems={"center"} pl={4} pb={2}>
                    <Box as={IoIosWarning} color="orange.500" size="3rem" mr={4} />
                    <Text fontSize={"1rem"} color="orange.400">
                        {translate("noEmailForPOSRegistration")}
                        <RLink
                            to={`/admin/shop/${selectedShop?.id}`}
                            style={{ textDecoration: "underline", color: "inherit" }}
                            onClick={() => {
                                closeModal(modalNames.POS_ADMIN_MODAL);
                            }}
                        >
                            {translate("shopContactPerson")}
                        </RLink>
                        <Box as="span" mx={1}>
                            {translate("or").toLowerCase()}
                        </Box>
                        <RLink
                            to={`/admin/company/${selectedCompany?.id}`}
                            style={{ textDecoration: "underline", color: "inherit" }}
                            onClick={() => {
                                closeModal(modalNames.POS_ADMIN_MODAL);
                            }}
                        >
                            {translate("companySettings")}
                        </RLink>
                    </Text>
                </Flex>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <RHFormInput
                        control={control}
                        isMandatory
                        name="name"
                        formLabel={translate("cashRegisterName")}
                        placeholder={translate("cashRegisterName")}
                        fullWidth
                    />
                    <RHFormInput
                        control={control}
                        name="description"
                        formLabel={translate("description")}
                        placeholder={translate("description")}
                        fullWidth
                    />
                    <RHSelectInput
                        control={control}
                        name="menuIds"
                        noOptionsMessage={() => translate("noAlternative")}
                        formLabel={translate("menusCashierHasAccessTo")}
                        options={menuOptions}
                        isMulti
                    />
                    <RHSelectInput
                        control={control}
                        formLabel={translate("cardTerminal")}
                        placeholder={translate("chooseCardTerminal")}
                        name="connectedTerminalIds"
                        options={terminalOptions}
                        isMulti
                    />
                    <Grid templateColumns="1fr 1fr" mt={4} mb={2}>
                        <RHCheckBoxInput
                            control={control}
                            name="postponePaymentEnabled"
                            isDisabled={allowPhonePostponeOrders}
                        >
                            {translate("driveThrough")}
                        </RHCheckBoxInput>
                        <RHCheckBoxInput
                            control={control}
                            name="allowPhonePostponeOrders"
                            isDisabled={postponePaymentEnabled}
                        >
                            {translate("parkOrders")}
                        </RHCheckBoxInput>
                        <RHCheckBoxInput control={control} name="puckEnabled">
                            {translate("cashRegisterUsesPucks")}
                        </RHCheckBoxInput>
                        <RHCheckBoxInput control={control} name="wasteOrderEnabled">
                            {translate("wasteButton")}
                        </RHCheckBoxInput>
                        <RHCheckBoxInput control={control} name="autoRotateMenus">
                            {translate("autoRotateMenus")}
                        </RHCheckBoxInput>
                        <RHCheckBoxInput control={control} name="preferTakeAway">
                            {translate("perefenceIsTakeAway")}
                        </RHCheckBoxInput>
                        <RHCheckBoxInput control={control} name="sameCardRefund">
                            {translate("sameCardRefund")}
                        </RHCheckBoxInput>
                        <RHCheckBoxInput
                            control={control}
                            name="preferDigitalReceipts"
                            helperText={translate("chooseReceiptType")}
                        >
                            {translate("digitalReceipts")}
                        </RHCheckBoxInput>
                    </Grid>
                    <RHFormInput
                        control={control}
                        isMandatory
                        formLabel={translate("receiptPrinterIpAddress")}
                        name="receiptPrinter.ip"
                        placeholder={translate("receiptPrinterIpAddress")}
                        fullWidth
                    />
                    <Button pl={0} mb={4} type="button" onClick={() => checkPrinterIp(receiptIpAddress, deviceName)}>
                        <Indicator status={printerIsConnected ? "positive" : "inactive"} mx={2} size="lg" />
                        {printerIsConnected ? translate("receiptPrinterIsConnected") : translate("testConnection")}
                    </Button>
                </ModalBody>
                <ModalActions>
                    <Button
                        type="submit"
                        isDisabled={isSubmitting || !(isValid && isDirty) || canNotSubmitNewPos}
                        themeColor="green"
                        width="100%"
                        isLoading={isSubmitting}
                    >
                        {translate("save")}
                    </Button>
                </ModalActions>
            </form>
        </Modal>
    );
};
